import { tv } from "tailwind-variants";
import { BodySize, BodyType, TextAlign } from "./body.types";

export const bodyClassName = tv({
  variants: {
    textAlign: {
      [TextAlign.left]: "w-full text-left",
      [TextAlign.center]: "w-full text-center",
      [TextAlign.end]: "w-full text-right",
    },
    size: {
      [BodySize.large]: "text-lg/8 tracking-[-0.8px]",
      [BodySize.medium]: "text-base tracking-tighter",
      [BodySize.small]: "text-sm/6 tracking-[-0.6px]",
    },
    type: {
      [BodyType.emphasys]: "font-medium text-neutral-primary",
      [BodyType.basic]: "font-normal text-neutral-primary",
      [BodyType.muted]: "font-normal text-neutral-secondary",
    },
    underline: {
      true: "underline",
      false: "no-underline",
    },
    center: { true: "text-center" },
  },
  defaultVariants: {
    size: BodySize.medium,
    type: BodyType.basic,
    underline: false,
  },
});
