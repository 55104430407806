import React from "react";
import { Drawer } from "../../drawer";
import { HamburgerMenuButton } from "../hamburger-menu-button/hamburger-menu-button";
import { LoggedOutDropdownMenuContent } from "./components/content";
import { LoggedOutDropdownMenuFooter } from "./components";

export const LoggedOutDropdownMenu: React.FC = ({}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  return (
    <div className="flex items-center justify-center">
      <HamburgerMenuButton
        onClick={() => {
          setIsOpen(!isOpen);
        }}
        aria-label="logged out menu button"
      />
      <Drawer
        isOpen={isOpen}
        onOpenChange={setIsOpen}
        title={""}
        contentClassName="px-0"
        footerContent={<LoggedOutDropdownMenuFooter />}
      >
        <LoggedOutDropdownMenuContent />
      </Drawer>
    </div>
  );
};
