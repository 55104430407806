import React from "react";
import { Slot } from "@radix-ui/react-slot";
import { CaptionProps } from "./caption.types";
import { captionClassName } from "./caption.styles";

export const Caption: React.FC<CaptionProps> = ({
  asChild,
  underline,
  italic,
  type,
  color,
  center,
  ...props
}) => {
  const Comp = asChild ? Slot : "span";

  return (
    <Comp
      className={captionClassName({ underline, italic, type, color, center })}
      {...props}
    />
  );
};
