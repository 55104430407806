import Link from "next/link";
import React from "react";

export const LoggedOutDropdownMenuFooter: React.FC = ({}) => {
  return (
    <div className="flex w-full justify-between p-4">
      <Link
        className="flex cursor-pointer items-center gap-2 text-sm font-medium text-brand-primary-rest"
        href="/public/pp"
      >
        Privacy Policy
      </Link>
    </div>
  );
};
