import { tv } from "tailwind-variants";
import { TitleSize } from "./title.types";

export const titleClassName = tv({
  base: "font-medium text-neutral-primary",
  variants: {
    size: {
      [TitleSize.extraLarge]: "text-[32px]/10 tracking-[-1.6px]",
      [TitleSize.large]: "text-2xl/8 tracking-[-1.2px]",
      [TitleSize.medium]: "text-xl/8 tracking-tighter",
      [TitleSize.small]: "text-lg/6 tracking-tighter",
      [TitleSize.extraSmall]: "text-base tracking-[-0.6px]",
    },
    center: { true: "text-center" },
  },
  defaultVariants: {
    size: TitleSize.medium,
  },
});
