import * as React from "react";
import { Drawer as DrawerPrimitive } from "vaul";
import { cn } from "~/utils/cn";
import { Icon } from "../icon";

const Drawer = ({
  shouldScaleBackground = true,
  direction = "right",
  ...props
}: React.ComponentProps<typeof DrawerPrimitive.Root>) => (
  <DrawerPrimitive.Root
    shouldScaleBackground={shouldScaleBackground}
    direction={direction}
    {...props}
  />
);
Drawer.displayName = "Drawer";

const DrawerTrigger = DrawerPrimitive.Trigger;

const DrawerPortal = DrawerPrimitive.Portal;

const DrawerClose = DrawerPrimitive.Close;

const DrawerOverlay = React.forwardRef<
  React.ElementRef<typeof DrawerPrimitive.Overlay>,
  React.ComponentPropsWithoutRef<typeof DrawerPrimitive.Overlay> & {
    onOverlayClick?: () => void;
  }
>(({ className, onOverlayClick, ...props }, ref) => (
  <DrawerPrimitive.Overlay
    ref={ref}
    onClick={onOverlayClick}
    className={cn("fixed inset-0 z-50 bg-neutral-overlay/50", className)}
    {...props}
  />
));
DrawerOverlay.displayName = DrawerPrimitive.Overlay.displayName;

const DrawerContent = React.forwardRef<
  React.ElementRef<typeof DrawerPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof DrawerPrimitive.Content> & {
    handlerOnOverlayClick?: () => void;
  }
>(({ className, children, handlerOnOverlayClick, ...props }, ref) => (
  <DrawerPortal>
    <DrawerOverlay onOverlayClick={handlerOnOverlayClick} />
    <DrawerPrimitive.Content
      ref={ref}
      className={cn(
        "fixed right-0 top-0 z-[70] flex h-screen w-full max-w-[35rem] flex-col justify-start bg-white focus-visible:outline-none",
        className,
      )}
      {...props}
    >
      {children}
    </DrawerPrimitive.Content>
  </DrawerPortal>
));
DrawerContent.displayName = "DrawerContent";

interface DrawerHeaderProps extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
  children?: React.ReactNode;
  actions?: React.ReactNode;
  handlerOnCloseButtonClick?: () => void;
}

const DrawerHeader = ({
  className,
  children,
  actions,
  handlerOnCloseButtonClick,
  ...props
}: DrawerHeaderProps) => (
  <div
    className={cn(
      "flex items-center justify-between border-b border-bg-neutral-30 bg-neutral-00 p-4",
      className,
    )}
    {...props}
  >
    <div className="flex items-center gap-1">
      <div className="flex">
        <DrawerClose onClick={handlerOnCloseButtonClick}>
          <Icon icon="fa-solid fa-xmark cursor-pointer text-base leading-4 font-normal text-icon-neutral-20" />
        </DrawerClose>
      </div>
      <div>{children}</div>
    </div>
    <div className="flex flex-1 items-center justify-end">{actions}</div>
  </div>
);
DrawerHeader.displayName = "DrawerHeader";

const DrawerFooter = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => (
  <div
    className={cn(
      "mt-auto flex gap-2 border-t border-bg-neutral-30 bg-neutral-00 px-4 py-3",
      className,
    )}
    {...props}
  />
);
DrawerFooter.displayName = "DrawerFooter";

const DrawerTitle = React.forwardRef<
  React.ElementRef<typeof DrawerPrimitive.Title>,
  React.ComponentPropsWithoutRef<typeof DrawerPrimitive.Title>
>(({ className, ...props }, ref) => (
  <DrawerPrimitive.Title
    ref={ref}
    className={cn(
      "text-center text-base font-semibold leading-6 text-typography-high-contrast",
      className,
    )}
    {...props}
  />
));
DrawerTitle.displayName = DrawerPrimitive.Title.displayName;

const DrawerDescription = React.forwardRef<
  React.ElementRef<typeof DrawerPrimitive.Description>,
  React.ComponentPropsWithoutRef<typeof DrawerPrimitive.Description>
>(({ className, ...props }, ref) => (
  <DrawerPrimitive.Description
    ref={ref}
    className={cn(
      "text-center text-sm text-typography-low-contrast",
      className,
    )}
    {...props}
  />
));
DrawerDescription.displayName = DrawerPrimitive.Description.displayName;

export {
  Drawer as DrawerGroup,
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerPortal,
  DrawerTitle,
  DrawerTrigger,
};
