import Image from "next/image";
import { cn } from "~/utils/cn";
import { LogoutMenuButton } from "../../../logout-menu-button";
import { getCompanyLogoProps } from "../../navbar-template.utils";
import { NavBarTemplateProps } from "../../navbar-template.types";
import { signOut } from "next-auth/react";

export const NavBarTemplateLogoOnly = ({
  isLoggedOut,
  isLoading,
}: Partial<NavBarTemplateProps>) => {
  return (
    <div
      className={cn(
        "flex h-[3.313rem] w-full items-center justify-between border-b border-neutral-10 bg-neutral-00 px-4 py-[0.375rem]",
      )}
    >
      <div className="flex w-full items-center gap-6 lg:w-auto">
        <div className="h-5 w-24 overflow-hidden">
          <Image
            alt="Scalis Logo"
            {...getCompanyLogoProps(true)}
            className="h-5"
          />
        </div>
      </div>
      <div>
        {!isLoggedOut && !isLoading && <LogoutMenuButton onClick={signOut} />}
      </div>
    </div>
  );
};
