import { useRouter, usePathname } from "next/navigation";
import { LoginMenuButton } from "../../../login-menu-button";
import { SignupMenuButton } from "../../../signup-menu-button";
import { NavbarButtonsProps } from "./navbar-buttons.types";

const Divider = () => (
  <div className="hidden h-6 border-r border-neutral-00 lg:block" />
);

export const NavbarButtons: React.FC<NavbarButtonsProps> = ({
  isLoggedOut,
  accountButton,
  menuButton,
  isJobseekerDesktop,
  isJobseeker,
}) => {
  const router = useRouter();
  const pathname = usePathname();

  const getCallbackUrl = () => {
    if (!pathname) {
      return "";
    }

    return encodeURIComponent(pathname);
  };

  if (isLoggedOut) {
    return (
      <div className="flex gap-2">
        <LoginMenuButton
          onClick={() => router.push(`/login?callbackUrl=${getCallbackUrl()}`)}
        />
        <SignupMenuButton
          onClick={() => router.push(`/signup?callbackUrl=${getCallbackUrl()}`)}
        />
      </div>
    );
  }

  return (
    <>
      {accountButton && (
        <>
          <Divider />
          {accountButton}
        </>
      )}

      {(isJobseekerDesktop || !isJobseeker) && menuButton && (
        <>
          <Divider />
          {menuButton}
        </>
      )}
    </>
  );
};
