export enum TitleSize {
  extraSmall = "extra-small",
  small = "small",
  medium = "medium",
  large = "large",
  extraLarge = "extra-large",
}

export interface TitleProps {
  size?: TitleSize;
  asChild?: boolean;
  children: React.ReactNode;
  center?: boolean;
  className?: string;
}
